import { defineStore } from 'pinia'
import type { ActiveUserFragment } from '~/gql/graphql'


export const useMainStore = defineStore('main', {
    state: () => ({
        civiqaUser: null as ActiveUserFragment | null,
        chatOpen: false,
        syncMap: false,
        flyTo: null as { lat: number; lng: number } | null,
        refreshInvites: false,
    }),
})
